const customTheme = {
    button: {
        color: {
            primary: 'bg-black hover:bg-red-600 rounded-none text-white',
        },
    },
    carousel: {
        "root": {
            "base": "relative h-full w-full",
            "leftControl": "absolute top-0 left-0 flex h-full items-center justify-center px-4 focus:outline-none",
            "rightControl": "absolute top-0 right-0 flex h-full items-center justify-center px-4 focus:outline-none"
        },
        scrollContainer: {
            "base": "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth rounded-none  cursor-pointer",
        },
        "indicators": {
            "base": "h-2 w-2 rounded-full",
            "wrapper": "absolute bottom-5 left-1/2 flex -translate-x-1/2 space-x-2"
        },
        "control": {
            "base": "opacity-0 shadow-md group-hover:opacity-100 inline-flex h-8 w-8 hover:scale-125 transition ease-in-out duration-100 items-center justify-center rounded-full bg-white/30 group-hover:bg-white/100 group-focus:outline-none group-focus:ring-4 group-focus:ring-white dark:bg-gray-800/30 dark:group-hover:bg-gray-800/60 dark:group-focus:ring-gray-800/70 sm:h-10 sm:w-10",
            "icon": "h-5 w-5 text-slate-700 dark:text-gray-800  sm:h-6 sm:w-6"
        },

    },

    modal: {
        "root": {
            "base": "fixed top-0 right-0 left-0 z-50 h-modal h-screen overflow-y-auto overflow-x-hidden md:inset-0 md:h-full",
            "show": {
                "on": "flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80",
                "off": "hidden"
            },
            "sizes": {
                "sm": "max-w-sm",
                "md": "max-w-md",
                "lg": "max-w-lg",
                "xl": "max-w-5xl",
                // "2xl": "max-w-2xl",
                "3xl": "max-w-3xl",
                "4xl": "max-w-4xl",
                "5xl": "max-w-5xl",
                "6xl": "max-w-6xl",
                "7xl": "max-w-7xl"
            },
            "positions": {
                "top-left": "items-start justify-start",
                "top-center": "items-start justify-center",
                "top-right": "items-start justify-end",
                "center-left": "items-center justify-start",
                "center": "items-center justify-center",
                "center-right": "items-center justify-end",
                "bottom-right": "items-end justify-end",
                "bottom-center": "items-end justify-center",
                "bottom-left": "items-end justify-start"
            }
        },
        "content": {
            "base": "relative h-full w-full p-4 md:h-auto max-w-5xl",
            "inner": "relative rounded-3xl bg-white shadow dark:bg-gray-700 flex flex-col max-h-[90vh]"
        },
        "body": {
            "base": "p-6 flex-1 overflow-auto",
            "popup": "pt-0"
        },
        "header": {
            "base": "flex items-start justify-between rounded-t dark:border-gray-600 border-b p-5",
            "popup": "p-2 border-b-0",
            "title": "text-xl font-medium text-gray-900 dark:text-white",
            "close": {
                "base": "ltr:ml-auto rtl:mr-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white",
                "icon": "h-5 w-5"
            }
        },
        "footer": {
            "base": "flex items-center justify-start space-x-2 rtl:space-x-reverse rounded-b border-gray-200 p-6 dark:border-gray-600",
            "popup": "border-t"
        }
    },
    toast:
    {
        "root": {
            "base": "flex w-full max-w-xs items-center rounded-lg bg-white p-4 text-gray-500 shadow dark:bg-gray-800 dark:text-gray-400",
            "closed": "opacity-0 ease-out"
        },
        "toggle": {
            "base": "-mx-1.5 -my-1.5 rtl:mr-auto ltr:ml-auto inline-flex h-8 w-8 rounded-lg p-1.5 text-white hover:bg-black/30 focus:ring-2 focus:ring-gray-300 dark:bg-gray-800 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-white",
            "icon": "h-5 w-5 shrink-0"
        }
    }

};

export default customTheme;