import React from 'react';

export default class ToggleLang extends React.Component {
    state = {
        language: this.props.language
    }



    handleLangChange = (button) => {
        // var lang = this.buttonArText;
        this.props.onSelectLanguage(button.innerText.toLowerCase());
        this.setState({ language: button.innerText.toLowerCase() });
        button.innerText.toLowerCase() === 'العربية' ? document.documentElement.setAttribute('dir', 'rtl') : document.documentElement.setAttribute('dir', 'ltr');
    }
    render() {
        return (
            <div className='flex items-center justify-center pb-4 pt-8' dir="ltr">

                <div className="flex w-fit bg-gray-100 border border-gray-300 rounded-xl p-1">
                    {/* Arabic Language Tab */}
                    <button
                        ref={(ref) => this.buttonAr = ref}
                        onClick={() => this.handleLangChange(this.buttonAr)}
                        className={` ${this.state.language === 'العربية' ? 'rtl-text text-gray-900 bg-white border border-gray-300 font-medium' : 'text-gray-500 bg-gray-100 border border-gray-100 font-light'
                            }  rounded-lg text-md px-5 py-2 text-center inline-flex items-center`}
                    >
                        العربية
                    </button>

                    {/* French Language Tab */}
                    <button
                        ref={(ref) => this.buttonFr = ref}
                        onClick={() => this.handleLangChange(this.buttonFr)}
                        className={` ${this.state.language === 'french' ? 'text-gray-900 bg-white border border-gray-300 font-medium' : 'text-gray-500 bg-gray-100 border border-gray-100 font-light'
                            }  rounded-lg text-md px-5 py-2 text-center inline-flex items-center`}
                    >
                        French
                    </button>
                </div>
            </div>


        );
    }
}
