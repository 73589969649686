import { Minus, Plus, Trash } from "@phosphor-icons/react";
import { Drawer } from "flowbite";
import { useEffect } from "react";

export default function MyDrawer({ lang, cart, removeFromCart, clearCart, hideDrawer, updateQuantity }) {

    return (

        <div id="drawer-js-example" class="fixed top-0 right-0 z-40 h-screen p-4 overflow-y-auto transition-transform translate-x-full bg-white w-4/5 sm:w-3/5 lg:w-2/5  dark:bg-gray-800" tabindex="-1" aria-labelledby="drawer-right-label">
            <h5 id="drawer-right-label" class="inline-flex items-center mb-4 text-2xl font-semibold text-slate-900 dark:text-gray-400">

                {lang === 'french' ? "Panier" : "سلة التسوق"}
            </h5>
            <button type="button" onClick={hideDrawer} data-drawer-hide="drawer-js-example" aria-controls="drawer-js-example" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white">
                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                </svg>
                <span class="sr-only">Close menu</span>
            </button>
            {cart.length === 0 ? <img className='object-cover h-96 w-96 rounded-md' src="https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif" alt="..." /> :
                <>

                    {cart.map((product) => {
                        return (<div className="flex gap-4 border-b border-slate-300 items-center">
                            <img className='object-cover h-28 w-28 rounded-md' src={product.images[0]} alt="..." />
                            <div className="flex-grow flex flex-col gap-2 py-4">
                                <h5 className="text-md font-bold tracking-tight text-gray-900 dark:text-white">
                                    {lang === 'french' ? product.titleFr : product.titleAr}
                                </h5>
                                <p className="font-xs text-gray-600 dark:text-gray-400">
                                    {lang === 'french' ? product.descriptionFr : product.descriptionAr}
                                </p>
                                <div className="flex justify-between items-center">
                                    <div className="flex gap-2 items-center">
                                        <p className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                                            {product.price}dhs
                                        </p>
                                        <p className="text-md font-bold tracking-tight text-gray-900 dark:text-white line-through">
                                            {product.oldPrice}dhs
                                        </p>
                                        <p className="text-md font-bold tracking-tight text-gray-900 dark:text-white line-through">
                                            {product.quantity}dhs
                                        </p>
                                    </div>
                                    <div className="flex gap-4">
                                        <button type="button" onClick={() => updateQuantity(product.id, -1)} class="inline-flex items-center justify-center w-8 h-8 text-sm font-medium text-white bg-red-500 rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                                            <Minus size={20} weight="bold" />
                                            <span class="sr-only">Remove</span>
                                        </button>
                                        <button type="button" onClick={() => updateQuantity(product.id, 1)} class="inline-flex items-center justify-center w-8 h-8 text-sm font-medium text-slate-600 bg-white border border-slate-300 rounded-full hover:bg-slate-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500">
                                            <Plus size={20} weight="bold" />
                                            <span class="sr-only">Remove</span>
                                        </button>

                                    </div>
                                </div>
                            </div>

                        </div>
                        );
                    })}
                </>
            }
            <div className="flex justify-between items-center">
                <h5 className="text-md font-bold tracking-tight text-gray-900 dark:text-white">
                    {lang === 'french' ? "Total" : "المجموع"}
                </h5>
                <p className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {cart.reduce((total, product) => total + product.price * product.quantity, 0)}dhs
                </p>
                </div>
        </div>
    );
}