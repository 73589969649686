import { Button, Carousel, Modal } from 'flowbite-react';
import { useState } from 'react';

function MyModal({ openModal, setOpenModal, images, title, description, oldPrice, price, lang }) {

    return (
        <>
            <Modal dismissible show={openModal} onClose={() => setOpenModal(false)} position='center' size="5xl">
                <Modal.Body className="">
                    <div className=" flex flex-col md:flex-row gap-6">

                        <div className=" flex flex-col gap-4 flex-1">
                            <p className="text-3xl font-bold text-gray-900 dark:text-gray-400">
                                {title}
                            </p>
                            <p className="text-xl text-gray-600 dark:text-gray-400">
                                {description}
                            </p>
                            <div className="flex gap-2 justify-center items-center rtl:space-x-reverse">

                                <h6 className="text-3xl" dir="ltr">{price}dhs</h6>
                                <h6 className="text-xl font-light text-slate-500 line-through" dir="ltr">{oldPrice}dhs</h6>
                            </div>
                            <a type="button" href="https://wa.me/212656053893" target="_blank" rel="noreferrer" className={"cta-button border rounded-md text-center px-4 py-2 mt-2 block w-full no-underline text-black border-[1.5px ] relative transition-all duration-[0.35s]  border-solid border-black"}>
                                <span>{lang === 'french' ? 'Commander' : 'اطلب الآن'}
                                </span>
                            </a>

                        </div>
                        <div className="aspect-square group rounded-2xl overflow-hidden relative flex-1">
                            {images && <Carousel slide={false} dir="ltr">
                                {images.map((imgSrc) => {
                                    return (<div className='h-full relative'>
                                        <div class="h-full absolute inset-0 bg-gradient-to-t from-black/70 from-0% to-50% group-hover:opacity-100 rounded-md opacity-50 transition duration-200 ease-out">
                                        </div>
                                        <img className='object-cover w-full h-full' src={imgSrc} alt="..." />
                                    </div>)

                                })}
                            </Carousel> }
                        </div>
                    </div>

                </Modal.Body>
        
            </Modal>
        </>
    );
}

export default MyModal;