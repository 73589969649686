
import { FileSearch, MagnifyingGlassPlus, WhatsappLogo } from '@phosphor-icons/react';
import { Carousel, Toast } from 'flowbite-react';
import { useState } from 'react';
import MyModal from './modal';

export default function MyCard({ product, lang, addToCart, openToast, dismissToast }) {
    const [isHovered, setIsHovered] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const handleAddToCart = () => {
        addToCart(product); // Call the addToCart function and pass the product as an argument
        openToast();
        setTimeout(() => {
            dismissToast();
          }, 2000);
      };
    // console.log(images)
    return (<>

        <MyModal openModal={openModal} setOpenModal={setOpenModal} images={product.images} title={lang === 'french' ? product.titleFr : product.titleAr} description={lang === 'french' ? product.descriptionFr : product.descriptionAr} oldPrice={product.oldPrice} price={product.price} lang={lang} />
        <div onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)} className='bg-white cursor-pointer flex flex-col gap-2 overflow-hidden'>
            <div className="aspect-square group rounded-xl overflow-hidden relative">
                <div  onClick={()=> setOpenModal(true)} className="mx-auto w-[48px] p-2 h-[48px] top-1/2 left-1/2 transform rounded-full -translate-x-1/2 -translate-y-1/2 bg-black/60 absolute z-10  group-hover:opacity-100 opacity-0">

                    <MagnifyingGlassPlus size={32} weight="regular" className=" absolute z-3 text-white" />
                </div>

                {product.images && <Carousel slide={false} dir="ltr">
                    {product.images.map((imgSrc) => {
                        return (<div className='h-full relative'>
                            <div  onClick={()=> setOpenModal(true)}  class="h-full  absolute inset-0 bg-gradient-to-t from-black/70 from-0% to-50% group-hover:opacity-100 rounded-md opacity-50 transition duration-200 ease-out">
                            </div>
                            <img className='object-cover h-full w-full' src={imgSrc} alt="..." />
                        </div>)

                    })}
                </Carousel> }
                {/* <div class="z-9999 h-full absolute inset-0 bg-gradient-to-t from-black/70 from-0% to-100% group-hover:opacity-100 rounded-md opacity-0 transition duration-200 ease-out">
                                <div type="button" className='absolute hover:bg-white/40 bg-white/20 rounded-full p-3'>

                                
                                </div>
                            </div> */}
            </div>
            {/* <img
                src={imgSrc}
                alt=""
                className='hover:scale-110 transition-transform duration-1000 ease-in-out transform origin-center h-80 w-full object-cover'
            /> */}
            <div className="p-0 flex-grow flex flex-col">
                <div className='flex-grow flex flex-col gap-2'>
                    <h5 className="text-md font-bold tracking-tight text-gray-900 dark:text-white">
                        {lang === 'french' ? product.titleFr : product.titleAr}
                    </h5>
                    {/* <p className="font-xs text-gray-600 dark:text-gray-400">
                        {description}
                    </p> */}
                </div>
                <div className="flex flex-col justify-between items-start pt-2">
                    <div className="flex gap-2 justify-center items-center rtl:space-x-reverse">

                        <h6 className="text-lg" dir="ltr">{product.price}dhs</h6>
                        <h6 className="text-md font-light text-slate-500 line-through" dir="ltr">{product.oldPrice}dhs</h6>
                    </div>
                    <button type="button" onClick={handleAddToCart} target="_blank" rel="noreferrer" className={"cta-button border rounded-md text-center px-4 py-2 mt-2 block w-full h-full no-underline text-black border-[1.5px ] relative transition-all duration-[0.35s]  border-solid border-black" + (!isHovered ? 'md:opacity-0' : 'md:opacity-100')}>
                        <span>{lang === 'french' ? 'Commander' : 'اطلب الآن'}
                        </span>
                    </button>
                </div>

            </div>
        </div>
    </>
    );
}
