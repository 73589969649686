
import React from 'react';
import { Basket, WhatsappLogo, } from "@phosphor-icons/react";
import { default as mylogo } from '../assets/shopx.svg';


export default class MyNavbar extends React.Component {

    render() {
        return (
            <div id="navbar" className="p-0 w-full fixed ease-in-out transitions duration-500 z-20 top-0">

                <nav id="navbar-body" class="backdrop-blur-xl  bg-white/70 dark:bg-gray-900 shadow-md">
                    <div class="flex flex-wrap items-center justify-between mx-auto p-4">
                        <a href="https://flowbite.com/" class="flex items-center space-x-3 rtl:space-x-reverse">
                            <img src={mylogo} class="h-8" alt="Flowbite Logo" />
                            <span class="self-center text-lg font-semibold whitespace-nowrap dark:text-white">{this.props.lang === 'french' ? "Blaaan.ma" : "بلاآآن"}</span>
                        </a>
                        <div class="flex md:order-2 md:gap-4 items-center space-x-3 md:space-x-0 rtl:space-x-reverse">
                            <div className=" relative flex p-2 bg-white hover:shadow-sm hover:bg-slate-100 rounded-full border border-slate-400">

                                <Basket size={24} weight="regular"/>
                                <div class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500  rounded-full -top-2 -end-2 dark:border-gray-900">{this.props.cartCount}</div>
                            </div>
                            <button type="button" class="inline-flex gap-2 text-white bg-black hover:bg-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-md px-4 py-2 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800 rounded-full"><WhatsappLogo size={24} weight="light" />Whatsapp</button>
                            {/* <button data-collapse-toggle="navbar-sticky" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-sticky" aria-expanded="false">
                                <span class="sr-only">Open main menu</span>
                                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
                                </svg>
                            </button> */}
                        </div>
                        <div class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-sticky">
                            <p>{this.props.tagline}</p>
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}

window.onscroll = function () { scrollFunction() };

function scrollFunction() {
    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
        document.getElementById("navbar").classList.add("pt-2", "px-4", "md:px-20", "lg:px-40");
        document.getElementById("navbar-body").classList.add("rounded-full", 'mx-auto');
    } else {
        document.getElementById("navbar").classList.remove("pt-2", "px-4", "md:px-20", "lg:px-40");
        document.getElementById("navbar-body").classList.remove("rounded-full", 'mx-auto');
    }
}
