import './App.css';
import React, { useEffect, useState } from 'react';
import { Carousel, Flowbite, Toast } from 'flowbite-react';
import MyNavbar from './components/navbar';
import ToggleLang from './components/toggleLang';
import MyCard from './components/card';
import customTheme from './theme';
import { Basket } from '@phosphor-icons/react';
import { showDrawer, hideDrawer } from './utils';
import MyDrawer from './components/drawer';
import { db } from './firebase';
import { collection, getDocs } from "firebase/firestore";


/*
* $targetEl (required)
* options (optional)
* instanceOptions (optional)
*/



function App() {
  
  const handleLanguage = (langValue) => {
    setLanguage(langValue);
    
  }
  
  
  const [language, setLanguage] = useState('العربية');
  const [cartItems, setCartItems] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [newProducts, setNewProducts] = useState([]);

  // Update newProducts state after fetching data
  useEffect(() => {
    const fetchProducts = async () => {
      const querySnapshot = await getDocs(collection(db, "products"));
      const productsData = [];
      querySnapshot.forEach((doc) => {
        productsData.push(doc.data());
      });
      setNewProducts(productsData);
    };
  
    fetchProducts();
  }, []); 

  


  // Define addToCart function
  const addToCart = (product) => {
    const existingItemIndex = cartItems.findIndex(item => item.id === product.id);
    if (existingItemIndex !== -1) {
      const updatedCart = [...cartItems];
      const existingItem = updatedCart[existingItemIndex];
      if (existingItem.quantity < 3) {
        existingItem.quantity += 1;
      }
      setCartItems(updatedCart);
    } else {
      setCartItems([...cartItems, { ...product, quantity: 1 }]);
    }
  };
  
  const updateQuantity = (productId, delta) => {
    const updatedCart = cartItems.map(item => {
      if (item.id === productId) {
        const newQuantity = Math.max(0, Math.min(item.quantity + delta, 3));
        if (newQuantity === 0) {
          return null; // Signal to remove this item from the cart
        }
        return { ...item, quantity: newQuantity };
      }
      return item;
    }).filter(Boolean); // Remove null values
    setCartItems(updatedCart);
  };
  
  
  const removeFromCart = (productId) => {
    const updatedCart = cartItems.filter(item => item.id !== productId);
    setCartItems(updatedCart);
  };
  const openToast = () => {
    setShowToast(true);
  };
  const dismissToast = () => {
    setShowToast(false);
  };


  // drawer.show();

  return (
    <Flowbite theme={{ theme: customTheme }}>

      <div type="button" class='fixed bottom-6 z-30 right-6' dir="ltr" data-drawer-target="drawer-js-example" data-drawer-show={showToast} data-drawer-placement="right" aria-controls="drawer-js-example" onClick={showDrawer}>
        <div className=" w-fit flex p-4 bg-white hover:shadow-sm hover:bg-slate-100 rounded-full border border-slate-600">
          <Basket size={32} weight="regular" />
          <div class="absolute inline-flex items-center justify-center w-6 h-6 text-sm font-bold text-white bg-red-500 rounded-full -top-1.5 -end-1 dark:border-gray-900">{cartItems.length}</div>
        </div>
      </div>
      <MyDrawer lang={language} hideDrawer={hideDrawer} cart={cartItems} removeFromCart={removeFromCart} updateQuantity={updateQuantity}/>



      <MyNavbar tagline={language === 'french' ? "🎁 Livraison gratuite à partir de 249dhs" : "توصيل مجاني ابتدأ من 249 درهم 🎁"} lang={language} cartCount={cartItems.length} />

      <div className="h-[40vh] mt-[72px]">

        <Carousel dir="ltr">
          <div className='bg-slate-800 flex w-full h-full items-center' >


            <div className='flex-1'>
              <div className="flex flex-col p-4 sm:p-12 md:p-20 gap-4">

                <h2 className='text-3xl text-white'>Start your jouney</h2>
                <p className='text-md text-white'>Description Description Description Description Description Description Description</p>
                <div className="flex gap-4">
                  <a type='button' href='' className=' text-black text-lg bg-white px-6 py-2 text-center border border-white rounded-lg w-fit hover:opacity-90'>Commander</a>
                  <a type='button' href='' className=' text-white text-lg bg-black px-6 py-2 text-center border border-white rounded-lg w-fit hover:opacity-90'>Voir</a>
                </div>
              </div>
            </div>

            <div className="flex-1 h-full">
              <img src="https://ongpng.com/wp-content/uploads/2023/03/4.Fire-Boltt-Ninja_1500x1500.png" className=" h-full object-cover" alt="" />
            </div>

          </div>
          <div className=' flex w-full h-full' >


            <div className='flex-1'>
              <h2>Test</h2>
              <p>Description</p>
              <button className='cta-button'> <span>Button</span></button>
            </div>

            <div className="flex-1">
              <img src="https://source.unsplash.com/1000x600/?technology" className="object-cover w-full" alt="" />
            </div>

          </div>
        </Carousel>
      </div>
      {showToast && (

        <Toast className='fixed right-4 bottom-4 z-20 bg-green-700'>
          <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-800 text-green-700 dark:bg-cyan-800 dark:text-cyan-200">

          </div>
          <div className="mx-3 text-md text-white font-semibold">{language === "french" ? 'Ajouté au panier' : "ddddddddd ddddddddd"}</div>
          <Toast.Toggle onDismiss={() => setShowToast(false)} />
        </Toast>
      )}

      <ToggleLang onSelectLanguage={handleLanguage} language={language} />
      {/* <MyCarousel /> */}
      {language === 'french' ?

        <h1 className='text-xl text-center pt-2 lg:pt-4 pb-4'>Les plus vendus</h1> : <h1 className='text-xl text-center pt-2 lg:pt-4 pb-4'>الأكثر مبيعا</h1>
      }
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5  gap-6 lg:gap-8 p-4 md:px-12 lg:px-20" >

        {newProducts && newProducts.map((product) => {
          return <MyCard lang={language} addToCart={addToCart} product={product} openToast={openToast} dismissToast={dismissToast} />
        })}
        {/* <MyCard imgSrc={"https://cdn.manomano.com/images/images_products/33275900/P/116457345_1.jpg"} title={"Bouteille Isotherme en Acier Inoxydable 500ML"}/> */}
        {/* <MyCard imgSrc={"https://images-cdn.ubuy.com.sa/633c6775c89fb7023f3ce04d-colmi-p8-plus-smart-bracelet-smart-watch.jpg"} title={'Bracelet intelligent COLMI P8 Plus'}/> */}
      </div>
      <h1 className='text-lg font-light text-center pb-4 pt-4'>Plus d'articles, très prochainement...</h1>
      <div className="bg-slate-900 text-center text-slate-100 py-3 font-light">🩷 Keep in touch 🩷</div>



    </Flowbite>
  );
}

export default App;

